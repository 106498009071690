import React from 'react'
import { graphql } from 'gatsby'
import { usePageTitle } from '../../hooks/usePageTitle'
import { BlockContent } from '../../components/block-content';
import HeaderPadding from '../../layout/HeaderPadding'
import { Areas } from '../../components/Areas';
import SEO from '../../components/seo';
import styled from 'styled-components';
import { useContext } from 'react';
import { LanguageContext } from '../../layout/Layout';
import { Flex } from '../../components/Flex';
import { Sizes } from '../../styles/breakpoints';
import { fontSize, Weight } from '../../styles/fonts';
export const query = graphql`
query ContactUsPageQueryNb {
  data: sanityContactUsPage(_id: {regex: "/(drafts.|)contact-us-page/"}) {
    title {
      nb
      en
    },
    compassTitle {
      nb
      en
    },
    compassButton {
      nb
      en
    },
    compassSearching {
      nb
      en
    },
    _rawBodyLeft
    _rawBodyRight
  }
}

`

const CompassContainer = styled.div`
width: 100%;  
  svg {
    fill: ${({ theme }) => theme.primary};
    stroke: ${({ theme }) => theme.primary};
    width: 1em;
    height: 1em;
  }
  .font-h2 {
    font-weight: ${Weight.semi};
    font-size: ${fontSize("ingress")};
  }
  button.position-button {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.secondary};
    border: none;
    cursor: pointer;
    outline: inherit;
    margin-top: 1em !important;
    padding: 0.25em 1em;
    font-weight: ${Weight.semi} !important;
    font-size: ${fontSize('subtitle')} !important;
  }
  svg.compass-icon {
      fill: ${({ theme }) => theme.secondary};
      stroke: ${({ theme }) => theme.secondary};
      width: 1.5em;
      height: 1.5em;
      margin-left: 0.25em;
      margin-right: -0.1em;
  }
`

const BodyContainer = styled(Flex)`
width: 100%;  
  div {
    flex-grow: 1;
  }
  h2 {
    margin-bottom: 0.25em;
  }
`

const ContactPage = ({ data: { data } }) => {
  const lang = useContext(LanguageContext);
  const title = data.title[lang];
  usePageTitle(title);
  const compassTitle = data.compassTitle[lang];
  const compassButton = data.compassButton[lang];
  const compassSearching = data.compassSearching[lang];
  const bodyLeft = data._rawBodyLeft && data._rawBodyLeft[lang] || data._rawBodyLeft && data._rawBodyLeft['nb'] || [];
  const bodyRight = data._rawBodyRight && data._rawBodyRight[lang] || data._rawBodyRight && data._rawBodyRight['nb'] || [];

  return (
    <React.Fragment>
      <SEO title={title} />
      <HeaderPadding></HeaderPadding>
      <HeaderPadding></HeaderPadding>
      <CompassContainer>
        <gyro-compass lat={59.936284} long={10.7545088} compass-button={compassButton} compass-title={compassTitle} compass-searching={compassSearching} />
      </CompassContainer>
      <HeaderPadding></HeaderPadding>
      <BodyContainer justify="space-between" wrap>
        <BlockContent blocks={bodyLeft} />
        <BlockContent blocks={bodyRight} />
      </BodyContainer>
      <HeaderPadding></HeaderPadding>
    </React.Fragment>
  )
}

export default ContactPage

